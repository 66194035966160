<template>
  <v-dialog persistent  
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        <div class="abas" v-show="isEdit" width="100%">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px"
          >
            <!-- <v-tab href="#tab-1" class="abas-item">
              Estabelecimento
            </v-tab> -->
          </v-tabs>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <v-form class="box-form">
            <v-card-title class="modal-cadastro-titulo">
              {{ isEdit ? 'Editar Estabelecimento' : 'Cadastrar Estabelecimento' }}
            </v-card-title>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Dados do Estabelecimento
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                    <v-autocomplete
                      v-model="item.parentId"
                      :items="listaItensSelect"
                      :loading="isLoadingAutoComplete"
                      :search-input.sync="searchText"
                      :error-messages="institutionParentIdErrors"
                      :menu-props="{'content-class': 'autocomplete-menu'}"
                      @keyup="searchAutoComplete"
                      item-text="text"
                      item-value="value"
                      label="Selecione uma entidade"
                      class="autocomplete"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Buscando...
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>  {{ item.text }} </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCodErrors" v-model="item.cod" label="COD:" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" /></v-col>
                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionPercentageErrors" v-model="item.percentage" label="Porcentagem:" @input="$v.item.percentage.$touch()" @blur="$v.item.percentage.$touch()" max="100" /></v-col> -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNameErrors" v-model="item.name" label="Nome do estabelecimento:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionEmailErrors" v-model="item.email" label="Email:" @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionCnpjErrors" v-model="item.cnpj" label="CNPJ:" @input="$v.item.cnpj.$touch()" @blur="$v.item.cnpj.$touch()" v-mask="['##.###.###/####-##']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionPhoneErrors" v-model="item.phone" label="Telefone Comercial:" @input="$v.item.phone.$touch()" @blur="$v.item.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Endereço do Estabelecimento
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionCepErrors" v-model="item.cep" @keyup="buscarCep" label="CEP:" @input="$v.item.cep.$touch()" @blur="$v.item.cep.$touch()" v-mask="['#####-###']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionAddressErrors" v-model="item.address" label="Endereço:" @input="$v.item.address.$touch()" @blur="$v.item.address.$touch()" :disabled="cepValidate" /></v-col>
                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCityErrors" v-model="item.city" label="Cidade:" @input="$v.item.city.$touch()" @blur="$v.item.city.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionStateErrors" v-model="item.state" label="Estado:" @input="$v.item.state.$touch()" @blur="$v.item.state.$touch()" /></v-col> -->

                  <!-- componente de select de Estado e cidade -->
                  <searchUfCity
                    @addUf="item.state = arguments[0]"
                    @addCity="item.city = arguments[0]"
                    :propUf="item.state"
                    :propCity="item.city"
                    :errorMessagesCity="institutionCityErrors"
                    :errorMessagesState="institutionStateErrors"
                    :propDisabled="cepValidate" />

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNeighborhoodErrors" v-model="item.neighborhood" label="Bairro:" @input="$v.item.neighborhood.$touch()" @blur="$v.item.neighborhood.$touch()" :disabled="cepValidate" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionNumberErrors" v-model="item.number" label="Número:" @input="$v.item.number.$touch()" @blur="$v.item.estado.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.complement" label="Complemento:" /></v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
          <v-card-title>
            Dados do responsável da Estabelecimento:
          </v-card-title>
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNameErrors" v-model="item.responsible.name" label="Representante:" @input="$v.item.responsible.name.$touch()" @blur="$v.item.responsible.name.$touch()" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCpfErrors" v-model="item.responsible.cpf" label="CPF:" @input="$v.item.responsible.cpf.$touch()" @blur="$v.item.responsible.cpf.$touch()" v-mask="['###.###.###-##']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsiblePhoneErrors" v-model="item.responsible.phone" label="Telefone:" @input="$v.item.responsible.phone.$touch()" @blur="$v.item.responsible.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleBusinessPhoneErrors" v-model="item.responsible.businessPhone" label="Telefone Comercial:" @input="$v.item.responsible.businessPhone.$touch()" @blur="$v.item.responsible.businessPhone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field :error-messages="responsibleEmailErrors" v-model="item.responsible.email" label="Email:" @input="$v.item.responsible.email.$touch()" @blur="$v.item.responsible.email.$touch()"/></v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="modal-cadastro-form">
          <v-card-title>
            Endereço do responsável do Estabelecimento:
          </v-card-title>
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCepErrors" v-model="item.responsible.cep"  @keyup="buscarCepResponsible" label="CEP:" @input="$v.item.responsible.cep.$touch()" @blur="$v.item.responsible.cep.$touch()" v-mask="['#####-###']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleAddressErrors" v-model="item.responsible.address" label="Endereço:" @input="$v.item.responsible.address.$touch()" @blur="$v.item.responsible.address.$touch()" :disabled="cepValidateResponsible" /></v-col>
            <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCityErrors" v-model="item.responsible.city" label="Cidade:" @input="$v.item.responsible.city.$touch()" @blur="$v.item.responsible.city.$touch()" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleStateErrors" v-model="item.responsible.state" label="Estado:" @input="$v.item.responsible.state.$touch()" @blur="$v.item.responsible.state.$touch()" /></v-col> -->
            
            <!-- componente de select de Estado e cidade -->
            <searchUfCity
              @addUf="item.responsible.state = arguments[0]"
              @addCity="item.responsible.city = arguments[0]"
              :propUf="item.responsible.state"
              :propCity="item.responsible.city"
              :errorMessagesCity="responsibleCityErrors"
              :errorMessagesState="responsibleStateErrors"
              :propDisabled="cepValidateResponsible" />

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNeighborhoodErrors" v-model="item.responsible.neighborhood" label="Bairro:" @input="$v.item.responsible.neighborhood.$touch()" @blur="$v.item.responsible.neighborhood.$touch()" :disabled="cepValidateResponsible" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNumberErrors" v-model="item.responsible.number" label="Número:" @input="$v.item.responsible.number.$touch()" @blur="$v.item.responsible.number.$touch()" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.responsible.complement" label="Complemento:" /></v-col>
          </v-row>
        </v-card-text>

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loadingBtnCadastro" v-if="permiteAcao($route, 'edit') && item.status !== 'A'">Cadastrar</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'"
        >
          <products :entity="item"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import { formatPhone } from '@/core/service/utils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import axios from 'axios'
import config from '@/core/config'
import { merge } from 'lodash'

import validacao from './validacao'
import searchUfCity from '@/views/components/searchUfCity' 

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'


export default {
  name: 'ModalAtivarCadastroParceiro',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    searchUfCity
  },
  data: () => ({
    activeModal: false,
    loading: false,
    tab: 'tab-1',
    cepValidate: false,
    cepValidateResponsible: false
  }),

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('parceiros', {
      item: 'item',
      listaItensSelect: 'listaItensSelect'
    }),
    ...validacao,

    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    // this.obterItensSelect()
    // Events.$on('cadastro::openModalCadastro', () => {
    //   this.activeModal = true
    // })
  },
  methods: {
    ...mapActions('parceiros', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem']),
    // openModal () {
    //   this.activeModal = true
    // },

    mergeItens (estabelecimento, credenciador) {
      return merge(estabelecimento, credenciador)
    },

    requestsEntitiesProducts (id) {
      this.obterItensSelectProducts()
      this.obterEntityProduct({ entityId: id })
        .then(() => this.tab = 'tab-2')
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.tab = 'tab-1'
      this.$v.item.$reset()
      Events.$emit('closeModal::modalCadastro')
      this.cepValidate = false,
      this.cepValidateResponsible = false
    },

    async buscarCep () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.cep)){
        const result = await axios.get(config.apiViaCep + this.item.cep + "/json/")
        if (result.data.erro) {
          this.cepValidate = false
          return
        }

        this.cepValidate = true
        this.item.address = result.data.logradouro
        this.item.state = result.data.uf
        this.item.city = result.data.localidade
        this.item.neighborhood = result.data.bairro
      }
    },

    async buscarCepResponsible () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.responsible.cep)){
        const result = await axios.get(config.apiViaCep + this.item.responsible.cep + "/json/")
        if (result.data.erro) {
          this.cepValidateResponsible = false
          return
        }

        this.cepValidateResponsible = true
        this.item.responsible.address = result.data.logradouro
        this.item.responsible.state = result.data.uf
        this.item.responsible.city = result.data.localidade
        this.item.responsible.neighborhood = result.data.bairro
      }
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false 
      }

      this.loadingBtnCadastro = true

      const dados = {
        parentId: this.item.parentId || null,
        id: this.item.id,
        cod: this.item.cod,
        entityTypeId: 4,
        jsonData: {
          cod: this.item.cod,
          name: this.item.name,
          email: this.item.email.toLowerCase(),
          cnpj: this.item.cnpj.replace(/\D/g, ''),
          address: this.item.address,
          cep: this.item.cep.replace(/\D/g, ''),
          phone: formatPhone(this.item.phone),
          city: this.item.city,
          state: this.item.state,
          neighborhood: this.item.neighborhood,
          number: this.item.number,
          complement: this.item.complement,
          responsible: {
            name: this.item.responsible.name,
            email: this.item.responsible.email.toLowerCase(),
            cpf: this.item.responsible.cpf.replace(/\D/g, ''),
            phone: formatPhone(this.item.responsible.phone),
            businessPhone: formatPhone(this.item.responsible.businessPhone),
            cep: this.item.responsible.cep.replace(/\D/g, ''),
            address: this.item.responsible.address,
            state: this.item.responsible.state,
            city: this.item.responsible.city,
            neighborhood: this.item.responsible.neighborhood,
            number: this.item.responsible.number,
            complement: this.item.responsible.complement
          }
        }
      }


      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.loadingBtnCadastro = false
          this.closeModal()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loadingBtnCadastro = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }
    },
  },

  validations () {
    return  {
      item: {
        parentId: { required },
        cod: { required },
        email: { required, email },
        name: { required },
        cnpj: { 
          required
        },
        address: { required },
        cep: { required, minLength: minLength(9) },
        phone: { required },
        city: { required },
        state: { required },
        neighborhood: { required },
        number: { required },
        responsible: {
          name: { required },
          cpf: { required },
          email: { required, email },
          address: { required },
          cep: { required, minLength: minLength(9) },
          phone: { required },
          businessPhone: { required },
          city: { required },
          state: { required },
          neighborhood: { required },
          number: { required },
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.autocomplete {
  input {
    font-family: 'Roboto Mono', monospace;
  }
}

.v-autocomplete__content {
  .v-list-item__title {
    font-family: 'Roboto Mono', monospace;
  }
}
</style>