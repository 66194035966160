<template>
  <v-container>
    <v-row>
      <v-col cols="4" sm="2" class="modal-cadastro-form-col-imput">
        <v-autocomplete
          :items="optionsStates"
          :label="propLabelUF"
          v-model="uf"
          v-on:input="$emit('addUf', uf)"
          :error-messages="errorMessagesState"
          :disabled="propDisabled"
          :readonly="propReadonly"
          :dense="propDense"
          :outlined="propOutlined"
        ></v-autocomplete>
      </v-col>
      <v-col cols="8" sm="10" class="modal-cadastro-form-col-imput">
        <v-autocomplete
          :items="optionsCitys"
          label="Cidade"
          v-model="city"
          v-on:input="$emit('addCity', city)"
          :error-messages="errorMessagesCity"
          :disabled="propDisabled"
          :readonly="propReadonly"
          :dense="propDense"
          :outlined="propOutlined"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionStateErrors" v-model="item.state" label="Estado:" @input="$v.item.state.$touch()" @blur="$v.item.state.$touch()" /></v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { orderBy } from 'lodash'
import { errorSnackbar } from '@/core/service/utils'
import axios from 'axios'

export default {
  name: 'SearchUfCity',

  props: {
    propUf: {
      type: String,
      default: ''
    },

    propCity: {
      type: String,
      default: ''
    },

    errorMessagesCity: {
      type: Array,
      default: () => []
    },

    errorMessagesState: {
      type: Array,
      default: () => []
    },

    propDisabled: {
      type: Boolean,
      default: false
    },

    propReadonly: {
      type: Boolean,
      default: false
    },

    propDense: {
      type: Boolean,
      default: false
    },

    propOutlined: {
      type: Boolean,
      default: false
    },

    propLabelUF: {
      type: String,
      default: 'Estado'
    }
  },

  data: () => ({
    optionsStates: [],
    optionsCitys: [],
    uf: '',
    city: '',
    cepValidate: false
  }),
  
  watch: {
    'uf' (value) {
      this.buscarCidades(value)
    },

    'propUf' (value) {
      this.uf = value
    },

    'propCity' (value) {
      this.city = value
    }
  },

  created () {
    this.buscarEstados()
      .then()
      .catch(err => errorSnackbar(err))
    this.uf = this.propUf
    this.city = this.propCity
  },

  methods: {
    async buscarEstados () {
      const estados = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      this.optionsStates = orderBy(estados.data.map(item => {
        return {
          value: item.sigla,
          text: item.sigla
        }
      }), 'value', 'asc')

      this.optionsStates.unshift({ value: null, text: 'UF' })
    },

    async buscarCidades (state) {
      const cidades = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/distritos`)
      
      this.optionsCitys = orderBy(cidades.data.map(item => {
        return {
          value: item.nome,
          text: item.nome
        }
      }), 'value', 'asc')
      this.optionsCitys.unshift({ value: null, text: 'Selecione uma cidade' })
    }
  }
}
</script>

<style></style>